import { Observable } from 'rxjs';
import { API_URL_LOGIN, API_URL_CHECK_TOKEN, API_URL_LOGOUT, API_URL_GET_ROLES_FOR_USERS, API_URL_GET_ALL_ZONES } from '../config/apiRouteConfig/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AppService {
  isValid: boolean = false;
  isLoad: boolean = false;
  currentServiceRegionId = "1"
  rideType:any;
  roles = 'roles'
  zonesList =[]
  selectedZones =[]
  selectedZone:any ;

  name: string = "";
  idToken: string="";
  getCurrentServiceRegionId() {
    return this.currentServiceRegionId;
  }
  setCurrentServiceRegionId(serviceRegionId:any) {
    this.currentServiceRegionId = serviceRegionId;
    localStorage.setItem('zoneId',this.currentServiceRegionId)
  }

  getRideType() {
    return this.rideType;
  }
  setRideType(rideType:any) {
    this.rideType = rideType;
  }

  constructor(private _http: HttpClient) { }


  login(data:any): Observable<any> {
    return this._http.post(API_URL_LOGIN, data);
  }

  getAllZones(id: string) {
    return this._http.get(API_URL_GET_ALL_ZONES + id)
  }

  checkToken() {
    return this._http.get(API_URL_CHECK_TOKEN)
  }

  logout() {
    return this._http.post(API_URL_LOGOUT, {});
  }
  getRolesForUser(ssoId){
    return this._http.get(API_URL_GET_ROLES_FOR_USERS+ssoId+"/roles")
  }
  setGmailToken(token){
    this.idToken =token;
  }
  getGmailToken(){
    return this.idToken ;
  }

}
