import { AppService } from './../services/app.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import {COOKIE_SUFFIX} from '../config/apiRouteConfig'

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private cookieService: CookieService, private router: Router, private appService: AppService,
    private location: Location) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = this.cookieService.get('accessToken'+COOKIE_SUFFIX);
    if (!token || !this.appService.isValid) {
      this.appService.isValid = false;
      return true;
    }
  }

}
