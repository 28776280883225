import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DriverService } from 'src/app/services/driver/driver.service';
import { RideService } from 'src/app/services/ride/ride.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-review-pause-request',
  templateUrl: './review-pause-request.component.html',
  styleUrls: ['./review-pause-request.component.css']
})
export class ReviewPauseRequestComponent implements OnInit {
  @Input() driverData: any;
  @Input() imageUrl;
  @Output() closeDialog = new EventEmitter<string>();
  diplayConfirmPopup: boolean = false;
  diplayConfirmPopupRTH: boolean = false;
  selectedReason: any;
  dispatcherList = [];
  ridePanelFlag = {};
  otherReason;
  inputSoc;
  validSoc;
  manualNumber;
  load = false;
  displayDispatcherContact = false;
  selectedDispatchNumber;
  viewRejectReason: boolean = false;
  isValidSoc: boolean = false;

  constructor(
     private driverService: DriverService,
     private _rideService: RideService,
     private _loader: Ng4LoadingSpinnerService
     ) {
  
    
   }

  ngOnInit() {
  
    }
    
  
    
   verifySoc(event){
    console.log(event);
    let key = event.target.value;
    this.isValidSoc = false;
    console.log(this.isEmpty(key),'isempty');
    console.log(key < 100,'is less than 100');
    console.log(this.isNumber(key,''));
    
    if(this.isEmpty(key) || key > 100 || !this.isNumber(key,'') || !Number.isInteger(parseFloat(key)) || key.includes(".")){
      this.isValidSoc = true
    }else{
      this.isValidSoc = false;
    }
    
    
    

    
    }
    isNumber(value, acceptScientificNotation) {

      if(true !== acceptScientificNotation){
          return /^-{0,1}\d+(\.\d+)?$/.test(value);
      }

      if (true === Array.isArray(value)) {
          return false;
      }
      return !isNaN(parseInt(value, 10));
  }
   isEmpty(text: string): boolean{
      return (!text || text.trim() === "");
    };

  rejectReason(){
    if(this.selectedReason == null || 
      (this.selectedReason=='Other' && (this.otherReason == null || this.otherReason.replaceAll(" ","") == "" ))
      || (
        (this.driverData.rideDenyRequest.requestType == 'lowSocVerification' && this.selectedReason == 'Wrong SOC updated' && this.validSoc == null)
        )){
      Swal.fire({
        title: "Reason not selected",
        icon: "error",
        text: "Please select rejection reason"
      });
      return
    }

    let body={
      rideId: this.driverData.rideDenyRequest.rideId,
      driverSsoId : this.driverData['ssoId'],
      driverId: this.driverData['driverId'],
      rideDenyRequestId: this.driverData.rideDenyRequest['id'],
      requestType: this.driverData.rideDenyRequest['requestType'],
      issueType: this.driverData.rideDenyRequest['issueType'],
      pauseType: this.driverData.rideDenyRequest['pauseType'],
      rejectReasonKey:this.getRejectReasonKey(this.selectedReason),
      cancellationReason : this.selectedReason == 'Other' ? this.otherReason : this.selectedReason,
      validSoc: this.validSoc
    }
    if(this.driverData.rideDenyRequest['description'] == 'clicked'){
      body['description'] = 'clicked';
    }
    this._loader.show();
   
    
    this.driverService.rejectAssignment(body).subscribe(res => {
      this._loader.hide();
      this.viewRejectReason = false;
      Swal.fire({
        title: "Success",
        icon: "info",
        text: "Successfully rejected"
      });
      this.closeDialog.next('close dialog');
      // this.rideDernyRequest = null
    })
   }   


   markBreakdown(){
    this._loader.show();
    this.driverData.rideDenyRequest.requestStatus = "APPROVED";
    this.driverData.rideDenyRequest['ssoId'] = this.driverData['ssoId'];
console.log(this.driverData.rideDenyRequest);

    this.driverService.markBreakdown(this.driverData.rideDenyRequest)
    .subscribe(res =>{
      this._loader.hide();
      this.diplayConfirmPopup = false;
      Swal.fire({
        title: "Success",
        icon: "info",
        text: "Successfully approved"
      });
      this.closeDialog.next('close dialog');
    })    
  }

   callToDriver(event) {
    if(event)
      event.stopPropagation();
    this.selectedDispatchNumber = localStorage.getItem('dispatcherNum');

    if (!this.selectedDispatchNumber) {
      this.callToDriverDialog(event)
    } else {
      if(this._rideService)
      this._rideService.callConnectToDriver(this.selectedDispatchNumber, this.driverData.rideDenyRequest.driverId,false).subscribe((res => {
        this.displayDispatcherContact = false;
        Swal.fire({
          title: "Connecting!",
          text: "Your call is connecting to Driver ",
          timer: 3000
        }).then(result => {
          event.stopPropagation();
          this.displayDispatcherContact = false;
        })
      })) 
      else 
      this._rideService.callConnectToDriver(this.selectedDispatchNumber, this.driverData.rideDenyRequest.driverId, false).subscribe((res => {
        this.displayDispatcherContact = false;
        Swal.fire({
          title: "Connecting!",
          text: "Your call is connecting to Driver ",
          timer: 3000
        }).then(result => {
          event.stopPropagation();
          this.displayDispatcherContact = false;
        })
      }))

    }
  }

  callToDriverDialog(event) {
    this._rideService.getDispatcherList().subscribe((res => {
      this.dispatcherList = res.response;
      this.ridePanelFlag = {};
      this.displayDispatcherContact = true;
      if (!this.selectedDispatchNumber && this.dispatcherList && this.dispatcherList.length > 0)
        this.selectedDispatchNumber = this.dispatcherList[0]
      if(event)
        event.stopPropagation();
    }))

  }

  hide() {
    this.displayDispatcherContact = null
  }

  updateDispatcherNum() {
    if (this.selectedDispatchNumber == 'Other')
      this.selectedDispatchNumber = this.manualNumber

    localStorage.setItem('dispatcherNum', this.selectedDispatchNumber);
    this.displayDispatcherContact = false;
  }

  hideConfirmPopup(){
    this.diplayConfirmPopup = false;
    this.diplayConfirmPopupRTH = false;
  }

  getUrlArray(urlList){
    urlList = urlList.replace('[','');
    urlList = urlList.replace(']','');
   let  urlArray = urlList.split(",");
    return urlArray;
  }

  getRejectReasonKey(selectedReason){
    switch (selectedReason) {
      case "Wrong SOC updated": return "wrong_soc_updated";
      case "Photo is not clear": return "photo_not_clear";
      case "Wrong issue raised": return "wrong_issue_raised";
      case "Other": return "other";
    }
    return ""
  }

}
