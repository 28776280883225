import { API_URL_LIST_VEHICLE, API_URL_ADD_VEHICLE, API_URL_EDIT_VEHICLE, API_URL_GET_VEHICLE_REGISTRATION, API_URL_LIVE_MAP, API_URL_VEHICLE_SANITIZATION_TIME, API_URL_LIST_VEHICLE_ALL } from './../../config/apiRouteConfig/index';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class VehicleService {

  callListApi = true;

  constructor(private _http: HttpClient) { }
  getVehicles(vehicleNo,seek,count): Observable<any> {
    let url = API_URL_LIST_VEHICLE ;
    let params = []
    if (seek != null && count)
      params.push("seek=" + seek + "&count=" + count)
    if (params.length > 0)
      url += "?" + params.join("&")
   
    if(vehicleNo != "" && vehicleNo != null && vehicleNo != "undefined"){
      url = url + "&vehicleNumber=" + vehicleNo;
    }
    return this._http.get(url);
  }
  // fetchThisVehicle(vehicleId){
  //   return this._http.get();
  // }

  getVehiclesAll(): Observable<any> {
    return this._http
      .get(API_URL_LIST_VEHICLE_ALL);
  }
  addVehicle(data): Observable<any> {
    return this._http.post(`${API_URL_ADD_VEHICLE}`, data);
  }

  editVeihicle(data): Observable<any> {
    return this._http.put(`${API_URL_EDIT_VEHICLE}/${data.vehicleNumber}`, data);
  }

  getVehicleDetails(data) {
    return this._http.get(`${API_URL_GET_VEHICLE_REGISTRATION}vehicleNumber=${data}`)
  }
  getAllVehicles() {
    return this._http.get(`${API_URL_LIST_VEHICLE}`)
  }
  //  getAllDummyVehicles(){
  //   return this._http.get(`${DUMMY_API_URL_LIST_VEHICLE}`)
  // }
  getAllDetailsForLiveMap() {
    return this._http.get(`${API_URL_LIVE_MAP}`)
  }
  // getvehicleSanitizationTime(): Observable<any> {
  //   return this._http.get(`${API_URL_VEHICLE_SANITIZATION_TIME}`)
  // }
}
