//import { Urls } from './../../models/api.constants';
import { environment } from '../../../../environments/environment'
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

export class Headers {
    private static HEADERS: HttpHeaders;
    public static get headers() {
        return Headers.HEADERS;
    }
    public static addHeaders(headers) {
        if (!Headers.HEADERS) {
            Headers.HEADERS = new HttpHeaders();
        }
        Headers.HEADERS = Headers.HEADERS.append(headers.key, headers.value);
    }
    public static clearHeaders() {
        Headers.HEADERS = null;
    }
}


@Injectable({
  providedIn: 'root'
})
export class FormServiceService {

  constructor(private http: HttpClient) {}
  requestThisUrl(url: string, token?: string): Promise<{}> {
    console.log(url)
    const URL = url.includes('https://') || url.includes('http://') ? url : environment.baseUrl + url;
    return this.http.get<{}>(URL).toPromise();
  }
}
