import { NgForm } from '@angular/forms';
import { SettingService } from 'src/app/services/settings/setting.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { data } from '../lngLatMap';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {NgZone,ViewChildren,} from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { RiderService } from 'src/app/services/rider/rider.service';
import * as moment from 'moment';
import { PermissionForUsers } from 'src/app/config/apiRouteConfig/checkroles';
@Component({
  selector: 'app-overwriting-fare',
  templateUrl: './overwriting-fare.component.html',
  styleUrls: ['./overwriting-fare.component.scss']
})
export class OverwritingFareComponent implements OnInit {

  cols;
  fileData = {
    "actualFare": 0,
    "fromAddress": "",
    "fromRadius": 0,
    "fromLat": 0.00,
    "fromLong": 0.00,
    "toLat": 0.00,
    "toLong": 0.00,
    "status": true,
    "systemFare": 0,
    "toAddress": "string",
    "toRadius": 0,
    "createdBy": "",
    "allowedSource": null,
    "actualDistance":0
  }
  // fileData = {
  //   "actualDistance": 0,
  //   "fromAddress": "",
  //   "fromRadius": 0,
  //   "fromLat": 0.00,
  //   "fromLong": 0.00,
  //   "toLat": 0.00,
  //   "toLong": 0.00,
  //   "status": true,
  //   "systemDistance": 0,
  //   "toAddress": "string",
  //   "toRadius": 0,
  //   "createdBy": "",
  //   "allowedSource": null
  // }

  addNewFile;
  updateNewFile;
  idForUpdate;
  actualFare
  fromAddress
  fromRadius
  status
  systemFare
  toAddress
  toRadius;
  riderTableDetails = [];
  pickupLocation: any;
  dropoffLocation: any;
  @ViewChildren("from") public from;
  @ViewChildren("to") public to;
  lat = 28.497219416666667;
  lng = 77.08307966666666;
  sources = [{label: 'All', value: 'ALL'}];
  allowedSource;
  origin = { lat: 0, lng: 0 };
  destination = { lat: null, lng: null };
  zoom = 10;
  userMap = {}
  completeUserMap = {}
  submitForm: boolean = false;
  customTab :any =1;
  constructor(private settingService: SettingService,
    private mapApiLoader: MapsAPILoader,
    private ngZone: NgZone,
    private _loader: Ng4LoadingSpinnerService,
    private riderService:RiderService) { }

  ngOnInit() {
    
    this.getOverWritingFareList()
    this.cols = [
      { field: 'id', header: 'ID', isVisible: true, width: '20px' },
      { field: 'createdAt', header: 'Created At', isVisible: true, width: '60px' },
      { field: 'fromAddress', header: 'Location 1', isVisible: true, width: '300px' },
      { field: 'fromRadius', header: 'Radius 1', isVisible: true, width: '70px' },
      { field: 'toAddress', header: 'Location 2', isVisible: true, width: 'auto' },
      { field: 'toRadius', header: 'Radius 2', isVisible: true, width: '170px' },
      { field: 'systemFare', header: 'System Fare', isVisible: true, width: 'auto' },
      { field: 'actualFare', header: 'Updated Fare', isVisible: true, width: 'auto' },
      { field: 'status', header: 'Status', isVisible: true, width: 'auto' },
      { field: 'allowedSource', header: 'Allowed Source', isVisible: true, width: 'auto' },
      { field: 'action', header: 'Action', isVisible: true, width: 'auto' }
    ];
    this.riderService.getAllUsers().subscribe((res) => {
      let allUsers = res['response']
      allUsers.forEach(driver => {
        if (driver.ssoId != null) {
          this.userMap[driver.ssoId] = driver.firstName
          this.completeUserMap[driver.ssoId] = driver;
        }
      });
    })
    this.settingService.getSources().subscribe((res) => {
      if(res['response'] != null){
      res['response'].forEach((item)=> {
        this.sources.push({label: item, value: item})
      })
    }
    })
  }

  resetLatLong(input) {
    if (input == "from") {
      this.origin.lat = null;
      this.origin.lng = null;
    } else if (input == "to") {
      this.destination.lat = null;
      this.destination.lng = null;
    }
  }

  changeTab(tab:any){
    this.customTab = tab;
    if(this.customTab == 1){
      this.getOverWritingFareList();
    }
    else{
      this.getOverWritingFareDistanceList();
    }
  }

  resetScreenToDispalyMap(event?) {
    this.submitForm = false;
  }

  getOverWritingFareDistanceList() {
    this.settingService.getOverwritingFairDistance().subscribe((res) => {
      this.riderTableDetails = res['response']

      
      this.riderTableDetails.forEach((item)=> {
        item['createdAt'] = moment(item['createdTimestamp']).format('MMMM Do YY, h:mm:ss a')
        item['createdByName'] = this.userMap[Number(item.createdBy)]
      })
      
    })
  }
  getOverWritingFareList() {
    
      this.settingService.getOverwritingFares().subscribe((res) => {
        this.riderTableDetails = res['response']
  
        
        this.riderTableDetails.forEach((item)=> {
          item['createdAt'] = moment(item['createdTimestamp']).format('MMMM Do YY, h:mm:ss a')
          item['createdByName'] = this.userMap[Number(item.createdBy)]
        })
        
      })
    
    // this.customTab = 1;
  }
  uploadKMLFile() {
    this.addNewFile = true
  }
  updateFarePopUp(id, rowData) {
    this.submitForm = true;
    this.updateNewFile = true;
    this.addNewFile = false
    this.idForUpdate = id
    this.fileData.fromAddress = rowData.fromAddress
    this.fileData.toAddress = rowData.toAddress
    this.fileData.actualFare = rowData.actualFare
    this.pickupLocation = rowData.fromAddress
    this.fileData.fromRadius = rowData.fromRadius
    this.dropoffLocation = rowData.toAddress
    this.fileData.systemFare = rowData.systemFare
    this.fileData.status = rowData.status
    this.fileData.fromLat = rowData.fromLat
    this.fileData.fromLong = rowData.fromLong
    this.fileData.toLat = rowData.toLat
    this.fileData.toLong = rowData.toLong
    this.fileData.toRadius = rowData.toRadius
    this.fileData.allowedSource = rowData.allowedSource
    this.allowedSource=rowData.allowedSource
  }
  updateDistancePopUp(id, rowData) {
    this.submitForm = true;
    this.updateNewFile = true;
    this.addNewFile = false
    this.idForUpdate = id
    this.fileData.fromAddress = rowData.fromAddress
    this.fileData.toAddress = rowData.toAddress
   
    this.pickupLocation = rowData.fromAddress
    this.fileData.fromRadius = rowData.fromRadius
    this.dropoffLocation = rowData.toAddress

    // this.fileData.systemFare = rowData.systemFare
    // this.fileData.actualFare = rowData.actualFare
    this.fileData['systemDistance'] = rowData.systemDistance;
    this.fileData['actualDistance'] = rowData.actualDistance;

    this.fileData.status = rowData.status
    this.fileData.fromLat = rowData.fromLat
    this.fileData.fromLong = rowData.fromLong
    this.fileData.toLat = rowData.toLat
    this.fileData.toLong = rowData.toLong
    this.fileData.toRadius = rowData.toRadius
    this.fileData.allowedSource = rowData.allowedSource
    this.allowedSource=rowData.allowedSource
  }

  select(event){
console.log(event)

  }
  updateFareDistance() {
    if(this.fileData.fromRadius > 501){
      alert("Please fill radius 1 less than 500 meter")
      return;
    }
    if(this.fileData.toRadius > 501){
      alert("Please fill radius 2 less than 500 meter")
      return;
    }
    if(this.allowedSource == null || this.allowedSource.length<0){
      alert("Allowed Source can't be null")
      return;
    }
    this.fileData.allowedSource =  this.allowedSource
    this.settingService.updateCustomDistance(this.idForUpdate, this.fileData).subscribe((res: any) => {
      if (res.status == 'SUCCESS') {
        this.updateNewFile = false
      }
      if (res.statusCode >= 300) {
        Swal.fire({
          title: 'Hmm...',
          icon: 'info',
          text: res.message
        });
      } else {
        Swal.fire({
          title: 'Good!',
          icon: 'success',
          text: 'Distance is Updated successfully'
        });
        this.getOverWritingFareDistanceList()
      }
    })
  }


  updateFare() {
    if(this.fileData.fromRadius > 501){
      alert("Please fill radius 1 less than 500 meter")
      return;
    }
    if(this.fileData.toRadius > 501){
      alert("Please fill radius 2 less than 500 meter")
      return;
    }
    if(this.allowedSource == null || this.allowedSource.length<0){
      alert("Allowed Source can't be null")
      return;
    }
    this.fileData.allowedSource =  this.allowedSource
    this.settingService.updateFair(this.idForUpdate, this.fileData).subscribe((res: any) => {
      if (res.status == 'SUCCESS') {
        this.updateNewFile = false
      }
      if (res.statusCode >= 300) {
        Swal.fire({
          title: 'Hmm...',
          icon: 'info',
          text: res.message
        });
      } else {
        Swal.fire({
          title: 'Good!',
          icon: 'success',
          text: 'Fare is Updated successfully'
        });
        this.getOverWritingFareList()
      }
    })
  }

  createFarePopUp() {
    if(this.customTab != 1){
      this.customTab = 1;
      this.getOverWritingFareList();
    }
    this.addNewFile = true
  }
  createDistancePopup(){
    if(this.customTab != 2){
      this.customTab = 2;
      this.getOverWritingFareDistanceList();
    }

    this.addNewFile = true
  }
  deleteDistancePopUp(id) {
    Swal.fire({
      title: 'Delete Distance',
      text: "Do You Want to Delete Distance?",
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.value == true) {
        this.settingService.deleteOverWritingDistance(id).subscribe((res) => {
          if (res['status'] == "SUCCESS") {
            Swal.fire({
              title: "Deleted",
              icon: "success",
              text: "Sucessfully Deleted"
            });
          } else {
            Swal.fire({
              title: res['status'],
              icon: "error",
              text: res['message']
            });
          }
          this.getOverWritingFareDistanceList()
        })

      }
    })
  }
  deleteFarePopUp(id) {
    Swal.fire({
      title: 'Delete Fare',
      text: "Do You Want to Delete Fare?",
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.value == true) {
        this.settingService.deleteOverWritingFare(id).subscribe((res) => {
          if (res['status'] == "SUCCESS") {
            Swal.fire({
              title: "Deleted",
              icon: "success",
              text: "Sucessfully Deleted"
            });
          } else {
            Swal.fire({
              title: res['status'],
              icon: "error",
              text: res['message']
            });
          }
          this.getOverWritingFareList()
        })

      }
    })
  }

  ngAfterViewInit() {
    this.mapApiLoader.load().then(() => {
      let bound1 = data[1];
      let newArray = [];

      for (let i = 0; i < bound1.length; i++) {
        newArray.push(
          new google.maps.LatLng(bound1[i].latitude, bound1[i].longitude)
        );
      }

      let defaultBounds = new google.maps.LatLngBounds(...newArray);

      let autocomplete = new google.maps.places.Autocomplete(
        this.from.first.nativeElement,
        {
          fields: [
            "address_component",
            "adr_address",
            "formatted_address",
            "geometry",
            "place_id"
          ],
          componentRestrictions: { country: "IN" },
          bounds: defaultBounds
        }
      );
      autocomplete.setBounds(defaultBounds);

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.pickupLocation = place.formatted_address;
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.origin = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };
          this.zoom = 12;
        });
      });

      let autocomplete1 = new google.maps.places.Autocomplete(
        this.to.first.nativeElement,
        {
          fields: [
            "address_component",
            "adr_address",
            "formatted_address",
            "geometry",
            "place_id"
          ],
          componentRestrictions: { country: "IN" },
          bounds: defaultBounds
        }
      );
      autocomplete1.setBounds(defaultBounds);

      autocomplete1.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete1.getPlace();
          this.dropoffLocation = place.formatted_address;
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.destination = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };
          this.zoom = 12;
        });
      });
    });
  }

  createDistance(data:NgForm) {
    if (data.value.fromRadius ) {
      this.fromRadius = data.value.fromRadius
    }
    if (data.value.toRadius) {
      this.toRadius = data.value.toRadius
    }
    if (data.value.systemFare) {
      this.systemFare = data.value.systemFare
    }
    if (data.value.actualFare) {
      this.actualFare = data.value.actualFare
    }
    if (data.value.status) {
      this.status = data.value.status
    }
    if(this.fromRadius > 501){
      alert("Please fill radius 1 less than 500 meter")
      return;
    }
    if(this.toRadius > 501){
      alert("Please fill radius 2 less than 500 meter")
      return;
    }
    if(this.allowedSource == null || this.allowedSource.length<0){
      alert("Allowed Source can't be null")
      return;
    }
    this.fileData.fromAddress = this.pickupLocation
    this.fileData.createdBy = localStorage.getItem("ssoId"),
    this.fileData.fromRadius = this.fromRadius;
    this.fileData.toAddress = this.dropoffLocation;
    this.fileData.toRadius = this.toRadius;

    if(this.fileData.systemFare){
      delete this.fileData.systemFare
    }
    if(this.fileData.actualFare){
      delete this.fileData.actualFare;
    }
   this.fileData['systemDistance'] = this.systemFare;
   this.fileData['actualDistance'] = this.actualFare;
    // this.fileData.systemFare = this.systemFare;
   

    this.fileData.fromLat = this.origin.lat;
    this.fileData.fromLong = this.origin.lng;
    this.fileData.toLat = this.destination.lat;
    this.fileData.toLong = this.destination.lng;
    this.fileData.allowedSource = this.allowedSource;
    this.settingService.createOverWritingDistance(this.fileData).subscribe((res: any) => {
      this.addNewFile = false
      if (res.status == 'SUCCESS') {
        this.addNewFile = false
      }
      if (res.statusCode >= 300) {
        Swal.fire({
          title: 'Hmm...',
          icon: 'info',
          text: res.message
        });
      } else {
        Swal.fire({
          title: 'Good!',
          icon: 'success',
          text: 'Distance is Created successfully'
        });
        this.getOverWritingFareDistanceList();
        data.reset()
        this.pickupLocation = null
        this.dropoffLocation = null
      }


    })
  }
  createFare(data:NgForm) {
  
    if (data.value.fromRadius ) {
      this.fromRadius = data.value.fromRadius
    }
    if (data.value.toRadius) {
      this.toRadius = data.value.toRadius
    }
    if (data.value.systemFare) {
      this.systemFare = data.value.systemFare
    }
    if (data.value.actualFare) {
      this.actualFare = data.value.actualFare
    }
    if (data.value.status) {
      this.status = data.value.status
    }
    if(this.fromRadius > 501){
      alert("Please fill radius 1 less than 500 meter")
      return;
    }
    if(this.toRadius > 501){
      alert("Please fill radius 2 less than 500 meter")
      return;
    }
    if(this.allowedSource == null || this.allowedSource.length<0){
      alert("Allowed Source can't be null")
      return;
    }
    this.fileData.fromAddress = this.pickupLocation
    this.fileData.createdBy = localStorage.getItem("ssoId"),
    this.fileData.fromRadius = this.fromRadius
    this.fileData.toAddress = this.dropoffLocation
    this.fileData.toRadius = this.toRadius;

    this.fileData.systemFare = this.systemFare;
    this.fileData.actualFare = this.actualFare;

    this.fileData.fromLat = this.origin.lat
    this.fileData.fromLong = this.origin.lng
    this.fileData.toLat = this.destination.lat
    this.fileData.toLong = this.destination.lng
    this.fileData.allowedSource = this.allowedSource
    this.settingService.createOverWritingFare(this.fileData).subscribe((res: any) => {
   
      this.addNewFile = false
      if (res.status == 'SUCCESS') {
        this.addNewFile = false
      }
      if (res.statusCode >= 300) {
        Swal.fire({
          title: 'Hmm...',
          icon: 'info',
          text: res.message
        });
      } else {
        Swal.fire({
          title: 'Good!',
          icon: 'success',
          text: 'Fare is Created successfully'
        });
        this.getOverWritingFareList()
        data.reset()
        this.pickupLocation = null
        this.dropoffLocation = null
      }


    })
  }

  handleBlockSwitchChange(e, rider) {
    console.log(e,rider);
    
    const isBlocked = e.checked;
    const rowData = rider;
    return this.blockThisRider({ isBlocked, rowData });
  }
  blockThisRider({ rowData, isBlocked }) {
    const msg = isBlocked ? 'ACTIVE' : 'INACTIVE';
    Swal.fire({
      title: 'Warning',
      html: `Are you sure you want to <strong>${msg}</strong> this fare ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        if (rowData) {
          rowData.createdTimestamp = rowData.createdTimestamp
        }
        console.log(this.customTab);
        
        if(this.customTab == 1){
        
        this.settingService.updateFair(rowData.id, rowData)
          .subscribe(
            (response: any) => {
              if (response.statusCode >= 300) {
                Swal.fire({
                  title: 'Opps...',
                  icon: 'error',
                  text: response.message
                });
              } else {
                let title = `<strong>${msg}</strong>`
                let text = `This Fare is Succesfully <strong>${msg}</strong> `
                Swal.fire({
                  title: title,
                  icon: "success",
                  html: text
                });
                // this.getOverWritingFareList()
              }
             
            },
            err => {
              console.log(err);
            }
          )
        }
        else{
          this.settingService.updateCustomDistance(rowData.id, rowData)
          .subscribe(
            (response: any) => {
              if (response.statusCode >= 300) {
                Swal.fire({
                  title: 'Opps...',
                  icon: 'error',
                  text: response.message
                });
              } else {
                let title = `<strong>${msg}</strong>`
                let text = `This Fare is Succesfully <strong>${msg}</strong> `
                Swal.fire({
                  title: title,
                  icon: "success",
                  html: text
                });
                // this.getOverWritingFareList()
              }
             
            },
            err => {
              console.log(err);
            }
          )
        }
      }

      setTimeout(()=>{
        if(this.customTab == 1){
          this.getOverWritingFareList()
        }
        else{
          this.getOverWritingFareDistanceList();
        }
      },200)
   
      
    });
  }
  
  converthhmmToTime(hhmmTime) {
    let time = hhmmTime.split(":");
    let newTime = new Date();
    newTime.setHours(time[0])
    newTime.setMinutes(time[1])
    return newTime;
  }
  checkPermission(permissionString: String) {    
    return PermissionForUsers.checkPermission(permissionString);
  }
}
