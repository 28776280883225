import { DriverDutyComponent } from './component/driver-duty.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes:Routes=[
    {
        path:'',
        component:DriverDutyComponent
    }
]


@NgModule({
    imports:[RouterModule.forChild(routes)],
    exports:[RouterModule]
})
export class DutyRoutingModule{

}