import { ButtonModule } from 'primeng/button';
import { CommonsModule } from './../../common/common.module';
import { DutyService } from './../../../services/duty/duty.service';
import { DutyRoutingModule } from './duty-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverDutyComponent } from './component/driver-duty.component';
import {DialogModule} from 'primeng/dialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import { ILLUMINAIRE_URL } from "../../../../app/config/apiRouteConfig/index";
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {ListboxModule} from 'primeng/listbox';
import { ReviewPauseRequestComponent } from './review-pause-request/review-pause-request.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';


@NgModule({
  declarations: [DriverDutyComponent, ReviewPauseRequestComponent],
  imports: [
    CommonModule,
    DutyRoutingModule,
    NgbModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    SliderModule,
    CommonsModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    ListboxModule,
    RadioButtonModule,
    Ng4LoadingSpinnerModule.forRoot()
  ],
  providers: [
    
  ]
})
export class DutyModule { }
