import { API_URL_ALL_ACTIVE_HUBS,API_URL_ALL_HUB_DATA } from './../../config/apiRouteConfig/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HubService {

  constructor(private _http: HttpClient) { }

  getAllActiveHubs(){
    return this._http.get(API_URL_ALL_ACTIVE_HUBS);
  }
  getAllHubDetails(){
    return this._http.get(API_URL_ALL_HUB_DATA);
  }

}
