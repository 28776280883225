import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateformat'
})
export class DatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }
    const datesVal = value.split('/');
    let temp = datesVal[0];
    datesVal[0] = datesVal[1];
    datesVal[1] = temp;
    return datesVal.join(' ');
  }

}
@Pipe({
  name: 'timediff'
})
export class TimeDifference implements PipeTransform {
  transform(minorTime = Date.now() ) {
    if(!minorTime){
      minorTime = Date.now();
    }
    var  majorTime = Date.now();
    let diff = ( Math.floor((majorTime - minorTime) / 1000) ); //seconds

    var time = ' ago';
    if(diff<0){
      time = '';
    }
    diff = Math.abs(diff);
    if (diff < 60) {
      return  diff + ' sec' + time;
    }
    else if (diff >= 60 && diff < 3600) {
      let minute = Math.floor(diff / 60);
      return  minute + ' min' + time;
    }
    else {

      let minute = Math.floor(diff/60);
      //console.log('minute',minute);

      let hour = Math.floor(minute/60);
      //console.log('hours',hour);

      minute = Math.floor(minute - hour*60);
      //console.log('minute',minute);
      return  hour + ' hr ' + minute + ' min' + time;
    }
  }
}
