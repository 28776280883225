export class PermissionForUsers {
    public static checkPermission(rolePermission): boolean {
      try {
        const data = JSON.parse(localStorage.getItem("userRoles"))
        
        return data && data.includes(rolePermission);
      }
      catch(e) {
           console.error(e);
           return false;
      }
    }
}
