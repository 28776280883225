import { Router } from '@angular/router';
import { ErrorHandler, Injectable ,Injector} from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }
  handleError(error: any): void {

    const router = this.injector.get(Router);
    //  console.error(error.stack.toString());

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}