<div class="flex_column" style="margin-bottom: 25px;">
  <div class="flex_row" style="padding: 10px 85px;gap: 20px;">
    <div class="issue-img" *ngFor="let image of getUrlArray(driverData.rideDenyRequest.imageUrl)">
      <img [src]="image" style="width: 400px;height:350px" *ngIf="!image.includes('null')">
      <span style="text-align: center;display: inherit;" *ngIf="driverData.rideDenyRequest?.description">{{driverData.rideDenyRequest?.description}}</span>
    </div>
  </div>
  <div class="flex_row mt-4" style="justify-content: center;">
    <div>
      <button class="btn" (click)="callToDriver($event)" >Call Driver</button>
    </div>
    <div>
      <button class="btn btn-warning" style="margin: 0px 20px;" *ngIf="driverData.rideDenyRequest.requestType != 'lowSocVerification'" (click)="diplayConfirmPopup = !diplayConfirmPopup">Mark Breakdown</button>
      <button class="btn btn-warning" style="margin: 0px 20px;" *ngIf="driverData.rideDenyRequest.requestType == 'lowSocVerification'" (click)="diplayConfirmPopupRTH = !diplayConfirmPopupRTH">Approve RTH</button>
    </div>
    <div>
      <button class="btn btn-danger" (click)="viewRejectReason = !viewRejectReason">Reject Request</button>
    </div>
 </div>
</div>
<p-dialog header="Reject Reason" [(visible)]="viewRejectReason" [modal]="true" [responsive]="false" [style]="{
  maxWidth: '500px',
  maxHeight: '350px',
  minHeight: '350px',
  'z-index': '999'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1">
<div style="font-size: 1rem; padding: 20px;">
  Please provide the reason
  <div class="field-radiobutton" *ngIf="driverData.rideDenyRequest.requestType != 'flatTyre' && driverData.rideDenyRequest.requestType != 'lMode'">
      <p-radioButton name="city" value="Wrong SOC updated" label ="Wrong SOC updated" [(ngModel)]="selectedReason" inputId="city1"></p-radioButton>
  </div>
  <div class="field-radiobutton" *ngIf="driverData.rideDenyRequest.requestType != 'other'">
      <p-radioButton name="city" value="Photo is not clear" label="Photo is not clear"  [(ngModel)]="selectedReason" inputId="city2"></p-radioButton>
  </div>
  <div class="field-radiobutton" *ngIf="driverData.rideDenyRequest.requestType != 'lowSocVerification'">
      <p-radioButton name="city" value="Wrong issue raised"  label="Wrong issue raised" [(ngModel)]="selectedReason" inputId="city3"></p-radioButton>
  </div>
  <div class="field-radiobutton" *ngIf="driverData.rideDenyRequest.requestType != 'lowSocVerification'">
      <p-radioButton name="city" value="Other" label="Other" [(ngModel)]="selectedReason" inputId="city4"></p-radioButton>
  </div>

  <div class="field-radiobutton" *ngIf="selectedReason == 'Other'">
    <input pInputText type="text" [(ngModel)]="otherReason" class="form-control" placeholder="Other Reason"  />
</div>
  <div class="field-radiobutton" *ngIf="selectedReason == 'Wrong SOC updated' && driverData.rideDenyRequest.requestType == 'lowSocVerification'">
    <input pInputText type="text" [(ngModel)]="validSoc" class="form-control"  (input)="verifySoc($event)"  placeholder="Valid Soc"  />
</div>
  <button class="btn btn-danger" (click)="rejectReason()" [disabled]="isValidSoc">Submit</button>
</div>
</p-dialog>

<p-dialog header="Dispatcher" [(visible)]="displayDispatcherContact" [modal]="true" [responsive]="false" [style]="{
  maxWidth: '450px',
  minWidth: '400px',
  maxHeight: '550px',
  minHeight: '370px',
  'z-index': '999'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1" (onHide)="hide()">
  <div class="form-group">
    <label for="">Dispatcher List</label>
    <select name="dispatcherList" id="" class='form-control' [(ngModel)]="selectedDispatchNumber" ngModel required
      #p='ngModel'>
      <option *ngFor="let client of dispatcherList">{{client}}</option>
      <option>Other</option>
    </select>
    <br>
    <br>
    <input *ngIf="selectedDispatchNumber=='Other'" pInputText type="text" class="form-control"
      style="display: flex;width:100%;margin:auto" placeholder="Mobile No" [(ngModel)]="manualNumber" />
    <div class="alert alert-danger" *ngIf="p.touched && p.invalid">
      number is required
    </div>
  </div>
  <div class="col pt-2" style="display: flex;margin: auto;width: 100%;">
    <div style="display: flex;margin: 10px auto;">
      <button class="btn btn-primary" (click)="updateDispatcherNum()" [disabled]="load">
        Update
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="diplayConfirmPopup" [modal]="true" [responsive]="false" [style]="{
  maxWidth: '500px',
  maxHeight: '350px',
  minHeight: '350px',
  'z-index': '999'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1">
<div style="font-size: 1rem; padding: 20px;">
  Do you confirm breakdown request?
  <div class="flex_row mt-4" style="gap: 20px;justify-content: center">
    <button class="action-btn" (click)="markBreakdown()">Yes</button>
    <button class="action-btn" (click)="hideConfirmPopup()">No</button>
  </div>
</div>
</p-dialog>

<p-dialog [(visible)]="diplayConfirmPopupRTH" [modal]="true" [responsive]="false" [style]="{
  maxWidth: '500px',
  maxHeight: '350px',
  minHeight: '350px',
  'z-index': '999'
}" [minY]="70" [maximizable]="false" [baseZIndex]="1">
<div style="font-size: 1rem; padding: 20px;">
  Do you confirm RTH request?
  <div class="flex_row mt-4" style="gap: 20px;justify-content: center">
    <button class="action-btn" (click)="markBreakdown()">Yes</button>
    <button class="action-btn" (click)="hideConfirmPopup()">No</button>
  </div>
</div>
</p-dialog>