import { API_URL_DRIVER_ON_DUTY, API_URL_DRIVER_ON_DUTY_STATS, API_URL_DRIVER_OFFLINE, API_URL_DRIVER_FOR_ASSIGNMENT, API_URL_DRIVER_ONLINE } from './../../config/apiRouteConfig/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class DutyService {

  constructor(private _http: HttpClient) { }

  getDriverOnDuty(seekQuery,countQuery,criteriaQuery,driverStateQuery,hubNameQuery,vehicleQuery) {
    return this._http.get(API_URL_DRIVER_ON_DUTY+'?'+seekQuery+countQuery+criteriaQuery+driverStateQuery+hubNameQuery + vehicleQuery);
  }

  getDriverOnDutyStats() {
    return this._http.get(API_URL_DRIVER_ON_DUTY_STATS);
  }

  offlineDriverAction(driverId) {
    return this._http.post(`${API_URL_DRIVER_OFFLINE}/${driverId}/offline`, {});
  }

  onlineDriverAction(driverId) {
    return this._http.post(API_URL_DRIVER_ONLINE+driverId, {});
  }


  getDriversByRideRequestId(rideRequestId, seek, count, criteriaQuery) {
    return this._http.get(API_URL_DRIVER_FOR_ASSIGNMENT + '?ride_request_id=' + rideRequestId + '&seek=' + seek + '&count=' + count + criteriaQuery);
  }
  getDriversByRideRequestIdOld(rideRequestId) {
    return this._http.get(API_URL_DRIVER_ON_DUTY + '?ride_request_id=' + rideRequestId);
  }
}
